import { useCookies } from 'react-cookie'
import { useParams } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

// const translate = {
//     en: {
//         data: `Personal data protection policy`,
//         cookie: `Cookie policy`,
//         site: `General conditions of the terms of use`,
//     },
//     bg: {
//         data: `Политика за защита на личните данни`,
//         cookie: `Политика за бисквитки`,
//         site: `Общи условия на сайта`,
//     },
// }

const translate = {
    en: {
        data: `Data policy`,
        cookie: `Cookie policy`,
        site: `Terms of use`,
        consent: `We use cookies to enhance your experience on our site. By clicking "Accept" you consent to our use of cookies in accordance with our Cookie Policy.`,
        reject: `Reject`,
        accept: `Accept`,
    },
    bg: {
        data: `Политика за личните данни`,
        cookie: `Политика за бисквитки`,
        site: `Общи условия`,
        consent: `Ние използваме бисквитки, за да подобрим Вашето изживяване на нашия сайт. Като щракнете върху „Приемам“, Вие се съгласявате с използването на бисквитки от наша страна в съответствие с нашата Политика за бисквитки.`,
        reject: `Отказвам`,
        accept: `Приемам`,
    },
}

export default function Footer() {
    const { language } = useParams()
    const translation = translate[language]
    const [ cookies, setCookie ] = useCookies([ `consent` ])

    const reject = _ => setCookie(`consent`, false, { path: `/` })
    const accept = _ => setCookie(`consent`, true, { path: `/` })
    return <>
        <footer id="footer">
            <nav className="menu_terms">
                <HashLink className="menu_terms__item" to={ `/${ language }/data` } smooth>{ translation.data }</HashLink>
                <HashLink className="menu_terms__item" to={ `/${ language }/cookie` } smooth>{ translation.cookie }</HashLink>
                <HashLink className="menu_terms__item" to={ `/${ language }/site` } smooth>{ translation.site }</HashLink>
            </nav>
            <h1 className="copyright">© 2021 Limpio</h1>
            <aside className={ `consent ${ cookies.consent === false || cookies.consent === true ? `consent--received` : `` }` }>
                <p className="consent__text">
                    { translation.consent }
                    
                </p>
                <div className="consent__button_group">
                    <button className="consent__button consent__button--reject" onClick={ reject }>{ translation.reject }</button>
                    <button className="consent__button consent__button--accept" onClick={ accept }>{ translation.accept }</button>
                </div>
            </aside>
        </footer>
    </>
}
