import { Routes, Route, Navigate } from "react-router-dom"
// import './App.css';
import 'style/index.sass'


// import Reroute from "component/reroute"
import Translate from 'translate'

export default function App() {
  return (
    <>
    <Routes>
      <Route path=":language/*" element={ <Translate/> }/>
      {/* <Route path="" element={ <Reroute address="/en/home"/> }/> */}
      <Route path="" element={ <Navigate to="/en/home" replace/> }/>
    </Routes>
    </>
  )
}
